.enhanced_signin_page {
  .checkout {
    &__content {
      #sign-in-panel {
        .checkout {
          &__return-user {
            .form-item {
              .label {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
