.enhanced-delivery-page {
  .promotions-container {
    .email_promotions {
      .info-link {
        padding-top: 0;
      }
      .promotions-container .email_promotions {
        float: $ldirection;
      }
    }
  }
  .delivery-address-container {
    .edit-address-container {
      .default_ship_container.radio {
        display: block;
      }
      .select2-container {
        padding: 0;
        width: 100%;
      }
    }
  }
  &.active-panel-review {
    .review-panel {
      &.panel {
        .checkoutdotcom-container,
        .policy-container {
          padding-#{$ldirection}: 16px;
        }
        #checkout_complete .field-container {
          &.payment-type {
            border-bottom: 1px solid $color--mercury;
            .picker-radio {
              .related-media {
                float: $rdirection;
                margin-top: -2px;
                span {
                  display: inline-block;
                  padding-#{$rdirection}: 0;
                }
              }
            }
          }
        }
        .checkout__title {
          font-size: 36px;
          margin-bottom: 16px;
          text-align: center;
          text-transform: uppercase;
        }
      }
    }
    #checkout-review-panel {
      .content {
        margin: 16px;
      }
    }
  }
  .adpl {
    .field {
      &.adaptive-label-mode + label::before,
      &:required:valid + label::before {
        display: none;
      }
    }
  }
  .checkout {
    &__content {
      padding-top: 4px;
    }
    #shipping-panel.panel {
      .form-item {
        &.address4 {
          .valid_marker,
          .invalid_marker {
            display: none;
          }
        }
        &.address4,
        &.delivery_instructions,
        &.delivery_instructions_container {
          .field {
            &:focus,
            &:required:valid {
              border-color: $color--gray--lighter;
            }
          }
        }
      }
      .field-container {
        .form-item {
          input[type='text'],
          input[type='tel'],
          select {
            font-size: 12px;
            height: 54px;
            text-decoration: none;
          }
          .field + .error {
            display: none;
          }
          input[type='text'],
          input[type='tel'],
          textarea.field {
            &:focus {
              border-color: $color--gray--lighter;
            }
          }
        }
      }
      @include breakpoint($width-largest) {
        .new-addres-container {
          margin-top: 16px;
        }
      }
      .edit-shipping-address {
        .address {
          .field-container--grid {
            .form-item {
              .field {
                & + label {
                  display: block;
                  font-size: 12px;
                  height: 36px;
                  line-height: 1.5;
                  margin-top: -37px;
                  padding-#{$ldirection}: 0;
                  pointer-events: none;
                }
                &:focus + label,
                &.adaptive-label-mode + label {
                  &::before,
                  .label-content {
                    display: none;
                  }
                }
              }
              &.delivery_instructions {
                position: relative;
                label {
                  padding-#{$ldirection}: 0;
                  position: absolute;
                  top: 33px;
                }
                .field {
                  & + label {
                    &::before {
                      margin-top: 48px;
                    }
                  }
                }
                &_container {
                  .field {
                    height: 94px;
                    & + label {
                      position: absolute;
                      top: 42px;
                      &::before {
                        white-space: inherit;
                      }
                    }
                  }
                }
              }
              .valid_marker,
              .invalid_marker {
                display: none;
              }
              .valid_marker {
                & + .field {
                  border-color: $color--gray--lighter;
                  & + label {
                    &::before {
                      display: none;
                    }
                  }
                }
              }
              .invalid_marker {
                & + .field {
                  border-color: $color--gray--lighter;
                  & + label {
                    &::before {
                      color: $color--gray--lighter;
                    }
                  }
                  &:focus + label {
                    &::before {
                      display: none;
                    }
                  }
                  &:required {
                    &:invalid {
                      border-color: $color--gray--lighter;
                      & + label {
                        &::before {
                          color: $color--gray--lighter;
                        }
                      }
                    }
                    &:empty,
                    &:not(:checked) {
                      border-color: $color--gray--lighter;
                      & + label {
                        &::before {
                          color: $color--gray--lighter;
                          content: attr(data-required) attr(placeholder);
                          display: inline-block;
                          margin: 0 17px;
                          text-transform: uppercase;
                          transform-origin: left center;
                          transition-delay: 0;
                          transition-duration: 0.2s;
                          transition-timing-function: ease-out;
                          white-space: nowrap;
                        }
                      }
                      &:focus + label {
                        &::before {
                          display: none;
                        }
                      }
                    }
                    &.error,
                    &.checkedEmpty,
                    &.touched {
                      border-color: $color--gray--lighter;
                      & + label {
                        &::before {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      #checkout_shipping_panel {
        .select-address.form-container {
          .personal-details {
            .set-pickup-point {
              .checkout__panel-title {
                margin: 0 0 5px;
              }
              .select-pickup-point {
                select {
                  height: 54px;
                  text-decoration: none;
                }
                &-field {
                  position: relative;
                  &:first-child select {
                    margin-bottom: 22px;
                  }
                  &::after {
                    #{$rdirection}: 20px;
                    border: 5px solid transparent;
                    border-top-color: $color--black--opacity-54;
                    content: '';
                    pointer-events: none;
                    position: absolute;
                    top: 24px;
                    @include breakpoint($width-largest) {
                      display: none;
                    }
                  }
                }
                .select2-container {
                  margin-bottom: 22px;
                  width: 100% !important;
                  .select2-choice {
                    height: 54px;
                    .select2-chosen {
                      margin: 0 10px;
                    }
                    .select2-arrow {
                      b::before {
                        #{$rdirection}: 2px;
                        opacity: 0.6;
                        transform: rotate(0);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .email-and-sms-promotions {
          float: $ldirection;
          width: 100%;
          .accordion-icon {
            top: 13px;
          }
          .sms_input {
            padding: 18px 12px;
          }
        }
      }
      .billing-address-form-fields {
        .field-container--grid {
          .form-item {
            .field {
              & + label {
                display: block;
                font-size: 12px;
                height: 36px;
                line-height: 1.5;
                margin-top: -37px;
                padding-#{$ldirection}: 0;
                pointer-events: none;
              }
              &:focus + label,
              &.adaptive-label-mode + label {
                &::before,
                .label-content {
                  display: none;
                }
              }
            }
          }
        }
        .description {
          margin-bottom: 22px;
        }
      }
      .checkout-shipping-panel-section {
        &.address-list-added {
          & ~ .billing-address-form-fields:not(.hidden),
          & ~ .address-to-use.form-item .radio:last-child .picker-radio {
            border-bottom: 1px solid;
          }
          & ~ .address-to-use.form-item .radio:last-child .picker-radio {
            margin-#{$rdirection}: 0;
            &.checked {
              border-bottom: 0;
            }
          }
        }
      }
    }
    .column {
      &.checkout__sidebar {
        #continue-btn {
          .submit {
            .btn {
              visibility: visible;
            }
          }
        }
        #offer-code-panel {
          form {
            .form--offer_code_text,
            .form-submit {
              height: 54px;
            }
          }
        }
        #shopping-bag-wrapper-panel {
          border-bottom: 1px solid $color--black;
          @include breakpoint($width-largest) {
            border-bottom-color: $color--gray--dusty--disable;
          }
        }
        .ship-label-sec {
          .shipping-label-data {
            height: 42px;
          }
        }
      }
    }
    .shipmethod-panel {
      &__title {
        padding-#{$ldirection}: 16px;
        @include breakpoint($width-largest) {
          padding-#{$ldirection}: 0;
        }
      }
    }
  }
  &.checkout {
    .info-link-content {
      .info-link {
        margin-#{$ldirection}: 0;
      }
    }
    .checkout-shipping-panel-section {
      & + .select-menu {
        border: 0;
      }
      .select-address {
        .select-menu {
          &:not(.shipping-address-list) {
            border: 0;
          }
        }
      }
      &.address-list-added ~ .info-link-content {
        .info-link {
          margin-#{$ldirection}: 13px;
        }
      }
    }
    #shipping-panel.panel {
      #checkout_shipping_panel .form-item.address4 {
        .field:required:invalid {
          &:empty.touched,
          &:not(:checked).touched {
            border-color: $color--gray--lighter;
          }
        }
      }
    }
  }
  .panel {
    input,
    select {
      &:focus,
      &:required:valid {
        border-color: $color--gray--lighter;
      }
    }
  }
}
