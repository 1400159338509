#lpChat select.lp_select_field {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
  -o-appearance: menulist;
}

.lp_desktop #lpChat .lp_pages_area,
.lp_mobile #lpChat .lp_pages_area {
  #LP_DropDownQuestion_1,
  #LP_DropDownQuestion_2,
  #LP_DropDownQuestion_3,
  #LP_DropDownQuestion_4,
  #LP_DropDownQuestion_5,
  #LP_DropDownQuestion_6 {
    .lp_lpview_content:after {
      position: relative;
      bottom: 21px;
      font-family: 'icons';
      font-size: 24px;
      float: right;
      display: inline-block;
      content: '';
    }
  }
}

#lpChat select.lp_select_field {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -o-appearance: none;
}

.device-pc .lp_desktop #lpChat .lp_pages_area,
.device-mobile .lp_mobile #lpChat {
  #LP_DropDownQuestion_1,
  #LP_DropDownQuestion_2,
  #LP_DropDownQuestion_3,
  #LP_DropDownQuestion_4,
  #LP_DropDownQuestion_5,
  #LP_DropDownQuestion_6 {
    .lp_lpview_content:after {
      -webkit-transform: rotate(136deg);
      -moz-transform: rotate(136deg);
      -ms-transform: rotate(136deg);
      transform: rotate(136deg);
      margin-top: -4.5px;
      border-style: solid;
      border-color: transparent;
      border-left-width: 9px;
      border-right-width: 9px;
      border-top-width: 0;
      border-bottom-width: 9px;
      border-bottom-color: black;
      margin-top: 6px;
    }
  }
}

[dir='rtl'] {
  .lp_desktop #lpChat .lp_pages_area,
  .lp_mobile #lpChat .lp_pages_area {
    #LP_DropDownQuestion_1,
    #LP_DropDownQuestion_2,
    #LP_DropDownQuestion_3,
    #LP_DropDownQuestion_4,
    #LP_DropDownQuestion_5,
    #LP_DropDownQuestion_6 {
      .lp_lpview_content:after {
        float: left;
      }
    }
  }
  .device-pc .lp_desktop #lpChat .lp_pages_area,
  .device-mobile .lp_mobile #lpChat {
    #LP_DropDownQuestion_1,
    #LP_DropDownQuestion_2,
    #LP_DropDownQuestion_3,
    #LP_DropDownQuestion_4,
    #LP_DropDownQuestion_5,
    #LP_DropDownQuestion_6 {
      .lp_lpview_content:after {
        -webkit-transform: rotate(225deg);
        -moz-transform: rotate(225deg);
        -ms-transform: rotate(225deg);
        transform: rotate(225deg);
      }
    }
  }
}

@mixin txttrans() {
  &::-webkit-input-placeholder {
    text-transform: none;
  }
  &:-moz-placeholder {
    text-transform: none;
  }
  &:-ms-input-placeholder {
    text-transform: none;
  }
  &::-moz-placeholder {
    text-transform: none;
  }
}

#lpChat {
  input[type='text'] {
    @include txttrans();
  }
  input[type='email'] {
    @include txttrans();
  }
  textarea {
    @include txttrans();
  }
  select {
    text-transform: none;
  }
  option {
    text-transform: none;
  }
}

#footer_chat {
  display: inline-block;
}
