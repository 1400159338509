[dir='rtl'] {
  .site-header {
    &__tools {
      .site-bag {
        &__contents {
          #{$ldirection}: auto;
        }
      }
    }
  }
  .content-block-sitewide-banner {
    &__trigger {
      right: auto;
      left: 20px;
    }
  }
}

.site-header {
  height: 140px !important;
  @include breakpoint($bp--largest-up) {
    height: 132px !important;
    min-height: 132px;
  }
  &__fixed-wrapper {
    height: auto !important;
    .site-banner {
      height: 7em;
      @include breakpoint($bp--largest-up) {
        height: 6em;
      }
    }
  }
  .content-block-signup {
    &__success {
      padding: 0;
    }
  }
}

.submenu--sticky {
  position: unset;
}

.page-products {
  &.content-block-sitewide-banner {
    &--enabled {
      .submenu--sticky {
        margin-top: 12px;
      }
    }
  }
}

.page-products-products-bestsellers {
  .site-container {
    .hero-block {
      margin-top: 0;
    }
  }
}

.has-subnav {
  .site-container {
    position: relative;
  }
  .hero-block {
    margin-top: 70px;
  }
  .sec-nav {
    top: 10px !important;
    position: absolute;
  }
}
