.profile-page {
  .newsletter-info {
    label {
      max-width: 85%;
    }
  }
}

.favorites-page__content {
  .favorites {
    .favorites-back {
      display: none;
    }
    .favorites-board__item {
      .product--teaser {
        .product__footer {
          .product__price {
            text-align: #{$rdirection};
            display: block;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}

.past-purchases.account-page__panel {
  .orders-list {
    &__table {
      a.order-detail-item-link {
        white-space: nowrap;
      }
    }
  }
}

.pg_wrapper.payment-info-page {
  padding: 0 15px 0;
}

[dir='rtl'] {
  .carousel {
    .slick-prev {
      @include icon(arrow--left-medium);
      &:before {
        font-size: 12px;
      }
    }
    .slick-next {
      @include icon(arrow--right-medium);
      &:before {
        font-size: 12px;
      }
    }
  }
  .checkoutdotcom-container {
    .date-container,
    .code-container {
      @include breakpoint($bp--small-up) {
        margin-left: 1em;
        margin-right: 0;
      }
    }
  }
}

html {
  .site-container {
    .field-mobile-template {
      div {
        overflow: hidden;
      }
      section {
        overflow: hidden;
      }
    }
  }
}

.checkoutdotcom-container--account {
  .checkoutdotcom-field {
    @include swap_direction(border, 1px solid $color--gray--lightest);
    @include swap_direction(border-radius, 0);
    height: 60px;
    margin: 1em 0;
    padding: 0 12px;
    &.error {
      border-color: $color--red;
      box-shadow: none;
    }
  }
  .date-container,
  .code-container {
    @include breakpoint($bp--small-up) {
      width: 40%;
      float: $ldirection;
      margin-right: 1em;
    }
  }
}

.change-password {
  #cboxClose {
    color: $color--black;
  }
}
