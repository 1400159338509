.tabbed-products-block {
  &__tab {
    min-height: 60px;
  }
}

.product-foundation-vto {
  &__btn {
    @include swap_direction(padding, 6px 3px 24px 38px);
    width: 202px;
  }
  &__mobile-ctrl {
    .container {
      .product-size {
        @include swap_direction(margin, 1px 0 0 6px);
      }
    }
  }
}

[dir='rtl'] {
  .product-foundation-vto {
    &__btn {
      @include swap_direction(padding, 6px 38px 24px 3px);
      width: 218px;
    }
  }
}

.appt-book {
  &.services {
    .book-now-bar {
      &.top {
        .button {
          .price {
            float: $ldirection;
            margin: 0;
          }
        }
      }
    }
  }
}

.site-container {
  .field-mobile-template {
    .trending-looks-block-item-overlay-stage {
      overflow: scroll;
      top: 0;
      z-index: 999999;
      background: transparent;
      &__content {
        background-color: $color--white;
      }
    }
  }
}

.product--teaser {
  &.product--shaded {
    .product {
      &__link-to-spp {
        height: 93px;
      }
    }
  }
  .product {
    &__add-to-bag {
      height: 93px;
    }
  }
}
